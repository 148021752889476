<template>
    <label class="v-form-checkbox py-0 form-checkbox flex items-center space-x-2 cursor-pointer rounded-lg border border-transparent">
        <div class="flex items-center h-4">
            <input v-model="input" v-bind="attributes" :value="value" @change="toggle()">
        </div>

        <span class="text-sm text-gray-400">
            <slot />
        </span>
    </label>
</template>

<script>
export default {
    inheritAttrs: false,
    model: {
        prop: "checked",
        event: "change",
    },
    props: {
        checked: {
            type: null,
            required: false,
            default: () => false,
        },
        value: {
            type: [String, Number, Object],
            required: false,
            default: () => null,
        },
    },
    data() {
        return {
            input: this.checked,
            baseClasses: "focus:ring-0 focus:border-0 h-4 w-4 text-primary-600 border-default bg-black rounded-sm",
            disabledClasses: "opacity-75 cursor-not-allowed",
        };
    },
    computed: {
        classes() {
            return `${this.baseClasses} ${this.disabled ? this.disabledClasses : ""}`;
        },
        attributes() {
            return {
                ...this.$attrs,
                class: this.classes,
                type: "checkbox",
                checked: this.value,
            };
        },
    },
    watch: {
        checked: {
            handler(value) {
                this.input = value;
            },
        },
    },
    methods: {
        toggle() {
            this.$emit("change", this.input);
        },
    },
};
</script>
